// @mui
import { useTheme } from '@mui/material/styles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

// theme
// components
import Iconify from '../../components/iconify'
//

// ----------------------------------------------------------------------

type OrgDropdownButtonProps = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void
} & IconButtonProps

export default function OrgDropdownButton({ sx, onClick, ...other }: OrgDropdownButtonProps): React.ReactNode {
  const theme = useTheme()

  return (
    <IconButton
      size="small"
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        onClick(event)
      }}
      sx={{
        p: 0.5,
        zIndex: theme.zIndex.appBar + 1,
        '&:hover': {
          bgcolor: theme.palette.grey[300],
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify width={16} icon="eva:arrow-ios-downward-fill" />
    </IconButton>
  )
}
