// @mui
import { useTheme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import { Box, Typography, MenuItem } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import { useContext } from 'react'
import { IntegrationsContext } from 'src/providers/integrations'
import { usePathname } from 'src/routes/hooks'
// theme
import { useUserOrganizations } from 'src/api/organization'
import { getCurrentOrg, getCurrentOrgId, setCurrentOrgId } from 'src/utils/local_storage'
import CustomSquareAvatar from 'src/components/custom-avatar/CustomSquareAvatar'
import CustomPopover, { usePopover } from 'src/components/custom-popover'
import { App_Type_Key } from 'src/utils/axios'
import { bgBlur } from '../../theme/css'
// routes
import { RouterLink } from '../../routes/components'
// hooks
import { useOffSetTop } from '../../hooks/use-off-set-top'
// components

import Logo from '../../components/logo'
//
import { HEADER } from '../config-layout'
import HeaderShadow from './header-shadow'
import OrgDropdownButton from './org-dropdown-button'

// ----------------------------------------------------------------------

export default function HeaderSimple(): React.ReactNode {
  const popover = usePopover()
  const { organizations, isLoading: organizationsLoading } = useUserOrganizations()
  const currentPath = usePathname()

  const integrationsContext = useContext(IntegrationsContext)
  const { appType } = integrationsContext

  const theme = useTheme()
  const showOrgDropdown = currentPath.includes('settings-tab') || appType === 'tab_app'

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP)

  const buildOrgSelectionPopover = (): React.ReactNode => {
    const orgMenuItems = organizations
      .sort((a, b) => {
        const currentOrgId = getCurrentOrgId()
        if (a.id === currentOrgId) {
          return -1
        }
        if (b.id === currentOrgId) {
          return 1
        }

        return a.name.localeCompare(b.name)
      })
      .map((org) => {
        return (
          <MenuItem
            key={org.id}
            onClick={(): void => {
              const currentOrgId = getCurrentOrgId()
              if (currentOrgId === org.id) {
                popover.onClose()
                return
              }

              setCurrentOrgId(org.id)
              localStorage.setItem(App_Type_Key, appType)
              window.location.reload()
              popover.onClose()
            }}
          >
            <CustomSquareAvatar src={org.avatarUrl} alt={org.name} name={org.name} size="medium" sx={{ mr: 2 }} />
            <Typography variant="subtitle2">{org.name.slice(0, 15)}</Typography>
          </MenuItem>
        )
      })

    return (
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="top-right">
        {orgMenuItems}
      </CustomPopover>
    )
  }

  const buildOrgSelectionButton = (): React.ReactNode => {
    if (appType !== 'tab_app' && !showOrgDropdown) {
      return <Box />
    }

    if (organizationsLoading) {
      return <Box />
    }

    if (organizations.length <= 1) {
      return <Box />
    }

    const currentOrg = getCurrentOrg(organizations)

    if (!currentOrg) {
      return <Box />
    }

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <Box display="flex" alignItems="center" justifyContent="flex-start" mr={1}>
          <CustomSquareAvatar
            src={currentOrg?.avatarUrl}
            alt={currentOrg?.name}
            name={currentOrg?.name}
            size="small"
            sx={{ mr: 2 }}
          />

          <Box>
            <Typography variant="subtitle2">{currentOrg?.name}</Typography>
          </Box>
        </Box>

        <Box mt={0.1} display="flex" alignItems="center" justifyContent="center">
          <OrgDropdownButton
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation()
              popover.onOpen(event)
            }}
          />
        </Box>
      </Box>
    )
  }

  const buildHelpButton = (): React.ReactNode => {
    if (appType === 'tab_app') {
      return <Box />
    }

    return (
      <Link
        href="https://coffeepals.com/support"
        component={RouterLink}
        color="inherit"
        sx={{ typography: 'subtitle2' }}
      >
        Need help?
      </Link>
    )
  }

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Logo disabledLink />
          {buildOrgSelectionButton()}
        </Box>

        {buildHelpButton()}
        {buildOrgSelectionPopover()}
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  )
}
