import useSWR, { KeyedMutator } from 'swr'
import { CustomError } from 'src/api/utils'
import { endpoints, fetcher, poster } from 'src/utils/axios'
import { swrOpts } from 'src/providers/swr'
import logger from 'src/utils/logger'
import { getCurrentOrgId, setCurrentOrgId } from 'src/utils/local_storage'
import { OrganizationDocument, PublicOrganizationDocument } from '../../../models/organization/OrganizationInterface'
import { StandardResponse } from '../../../models/utils/UtilsInterface'
import { PublicUserDocument } from '../../../models/user/BaseUserInterface'

// ----------------------------------------------------------------------

interface UseCurrentOrganizationResult {
  currentOrganization: PublicOrganizationDocument | undefined
  isLoading: boolean
  isError: boolean
  isValidating: boolean
  mutateCurrentOrganization: KeyedMutator<PublicOrganizationDocument>
}

export function useCurrentOrganization(): UseCurrentOrganizationResult {
  const organizationId = getCurrentOrgId() as string
  const URL = endpoints.organization.get

  const params = organizationId ? { organizationId } : {}

  // we have to pass the fetcher and opts in here because useCurrentOrganization gets called
  // outside of the SWRProvider (in AuthProvider)
  const { data, error, isLoading, isValidating, mutate } = useSWR<PublicOrganizationDocument, CustomError>(
    [URL, params],
    fetcher,
    swrOpts
  )

  const response = {
    currentOrganization: data,
    isLoading,
    isValidating,
    isError: !!error,
    mutateCurrentOrganization: mutate,
  }

  return response
}

interface UseEnterpriseOrganizationResult {
  organizations: PublicOrganizationDocument[]
  isLoading: boolean
  isError: boolean
  isValidating: boolean
  mutateOrganizations: KeyedMutator<PublicOrganizationDocument[]>
}

export function useEnterpriseOrganizations(): UseEnterpriseOrganizationResult {
  const URL = endpoints.organization.get_enterprise_organizations

  // outside of the SWRProvider (in AuthProvider)
  const { data, error, isLoading, isValidating, mutate } = useSWR<PublicOrganizationDocument[], CustomError>(
    [URL, {}],
    fetcher,
    swrOpts
  )

  const response = {
    organizations: data ?? [],
    isLoading,
    isValidating,
    isError: !!error,
    mutateOrganizations: mutate,
  }

  // Setting the current organization id in local storage if it is not already set
  const currentOrgId = getCurrentOrgId() as string
  if (!currentOrgId && data && data.length > 0) {
    setCurrentOrgId(data[0].id)
  }

  return response
}

interface UseUserOrganizationsResult {
  organizations: PublicOrganizationDocument[]
  isLoading: boolean
  isError: boolean
  isValidating: boolean
  mutateOrganizations: KeyedMutator<PublicOrganizationDocument[]>
}

export function useUserOrganizations(): UseUserOrganizationsResult {
  const URL = endpoints.organization.get_user_organizations

  const { data, error, isLoading, isValidating, mutate } = useSWR<PublicOrganizationDocument[], CustomError>(
    [URL, {}],
    fetcher,
    swrOpts
  )

  const response = {
    organizations: data ?? [],
    isLoading,
    isValidating,
    isError: !!error,
    mutateOrganizations: mutate,
  }

  return response
}

export async function sendApplicationConsentPermissionEmailToAdmin({
  organizationId,
  adminEmail,
  permissionUrl,
  firstName,
  lastName,
}: {
  organizationId: string
  adminEmail: string
  permissionUrl: string
  firstName: string
  lastName: string
}): Promise<boolean> {
  try {
    const URL = endpoints.organization.application_permission_email_to_admin

    const response = (await poster([
      URL,
      { organizationId, adminEmail, permissionUrl, firstName, lastName },
    ])) as boolean

    return response
  } catch (error) {
    logger.error(
      'error in sendApplicationConsentPermissionEmailToAdmin',
      { organizationId, adminEmail, permissionUrl, firstName, lastName },
      error as Error
    )
    return false
  }
}

export async function requestToJoinOrg({ organizationId }: { organizationId: string }): Promise<boolean> {
  try {
    const URL = endpoints.organization.request_to_join_org

    const response = (await poster([URL, { organizationId }])) as boolean

    return response
  } catch (error) {
    logger.error('error in requestToJoinOrg', { organizationId }, error as Error)
    return false
  }
}

export type OrganizationOnboardingParams = {
  adminFirstName: string
  adminLastName: string
  organizationName: string
  organizationAvatarUrl: string
  organizationIndustry: string
  organizationEmployeeCount: string
  marketingChannelInfo: { selectionValue: string; selectionText: string }
  adminEmails: string[]
  anticipatedUsageInfo?: string[]
}

export async function organizationOnboardingAPICall({
  adminFirstName,
  adminLastName,
  organizationName,
  organizationAvatarUrl,
  organizationIndustry,
  organizationEmployeeCount,
  marketingChannelInfo,
  adminEmails,
  anticipatedUsageInfo,
}: OrganizationOnboardingParams): Promise<OrganizationDocument | StandardResponse> {
  if (!organizationName || organizationName.length === 0) {
    return {
      success: true,
      error: false,
      errorMessage: '',
    }
  }

  const params = {
    adminFirstName,
    adminLastName,
    organizationName,
    organizationIndustry,
    organizationTotalEmployee: organizationEmployeeCount,
    aboutCoffeePalsInfo: marketingChannelInfo,
    adminEmails,
    coffeepalsUsageInfo: anticipatedUsageInfo,
    organizationAvatarUrl,
  }
  try {
    const URL = endpoints.organization.organization_onboarding

    const response = (await poster([URL, params])) as OrganizationDocument

    if (!response) {
      throw new Error('Network response was not ok')
    }

    return response
  } catch (error) {
    logger.error('error in organizationOnboardingAPICall', params, error as Error)
    return {
      success: false,
      error: true,
      errorMessage: 'Failed to submit onboarding information',
    }
  }
}

interface OrganizationMembersResult {
  users: PublicUserDocument[]
  isLoading: boolean
  isError: boolean
  isValidating: boolean
  mutateUsers: KeyedMutator<PublicUserDocument[]>
}

export function useOrganizationMembers(): OrganizationMembersResult {
  const currentOrgId = getCurrentOrgId() as string
  const URL = endpoints.organization.get_members

  const { data, error, isLoading, isValidating, mutate } = useSWR<PublicUserDocument[], CustomError>(
    [URL, { organizationId: currentOrgId }],
    fetcher,
    swrOpts
  )

  const response = {
    users: data ?? [],
    isLoading,
    isValidating,
    isError: !!error,
    mutateUsers: mutate,
  }

  return response
}

export function useOrganizationPendingMembers(): OrganizationMembersResult {
  const currentOrgId = getCurrentOrgId() as string
  const URL = endpoints.organization.get_pending_members

  const { data, error, isLoading, isValidating, mutate } = useSWR<PublicUserDocument[], CustomError>(
    [URL, { organizationId: currentOrgId }],
    fetcher,
    swrOpts
  )

  const response = {
    users: data ?? [],
    isLoading,
    isValidating,
    isError: !!error,
    mutateUsers: mutate,
  }

  return response
}

export async function inviteMembersToOrganization({
  invitedMemberEmails,
  invitedUserIds,
}: {
  invitedMemberEmails: string[]
  invitedUserIds: string[]
}): Promise<boolean> {
  const currentOrgId = getCurrentOrgId() as string
  const URL = endpoints.organization.invite_members

  const response = (await poster([
    URL,
    { organizationId: currentOrgId, invitedMemberEmails, invitedUserIds },
  ])) as boolean

  return response
}

export async function updateOrganizationAdminsApiCall({
  adminIds,
}: {
  adminIds: string[] // An array of user IDs or admin data
}): Promise<boolean> {
  const currentOrgId = getCurrentOrgId() as string
  try {
    const URL = endpoints.organization.update_admins
    const params = { organizationId: currentOrgId, adminIds }

    const response = (await poster([URL, params])) as boolean

    return response
  } catch (error) {
    logger.error('updateOrganizationAdminsApiCall', { organizationId: currentOrgId, adminIds }, error as Error)
    return false
  }
}

export async function updateOrganizationManagersApiCall({ managerIds }: { managerIds: string[] }): Promise<boolean> {
  const currentOrgId = getCurrentOrgId() as string
  try {
    const URL = endpoints.organization.update_managers
    const params = { organizationId: currentOrgId, managerIds }

    const response = (await poster([URL, params])) as boolean

    return response
  } catch (error) {
    logger.error('updateOrganizationManagersApiCall', { organizationId: currentOrgId, managerIds }, error as Error)
    return false
  }
}

export async function updateOrganizationOwnerApiCall({
  ownerId,
}: {
  ownerId: string // The user ID of the new owner
}): Promise<boolean> {
  const currentOrgId = getCurrentOrgId() as string
  try {
    const URL = endpoints.organization.update_owner
    const params = { organizationId: currentOrgId, ownerId }

    const response = (await poster([URL, params])) as boolean

    return response
  } catch (error) {
    logger.error('updateOrganizationOwnerApiCall', { organizationId: currentOrgId, ownerId }, error as Error)
    return false
  }
}

export async function acceptInvitationByUser({ organizationId }: { organizationId: string }): Promise<boolean> {
  const URL = endpoints.organization.accept_invitation_by_user
  const params = { organizationId }

  const response = (await poster([URL, params])) as boolean

  return response
}

export async function withdrawInvitation({ email }: { email: string }): Promise<boolean> {
  const currentOrgId = getCurrentOrgId() as string
  const URL = endpoints.organization.withdraw_invitation
  const params = { organizationId: currentOrgId, email }

  const response = (await poster([URL, params])) as boolean

  return response
}

export async function acceptPendingMember({ userId }: { userId: string }): Promise<boolean> {
  const currentOrgId = getCurrentOrgId() as string
  const URL = endpoints.organization.accept_pending_member
  const params = { organizationId: currentOrgId, userId }

  const response = (await poster([URL, params])) as boolean

  return response
}

export async function declinePendingMember({ userId }: { userId: string }): Promise<boolean> {
  const currentOrgId = getCurrentOrgId() as string
  const URL = endpoints.organization.decline_pending_member
  const params = { organizationId: currentOrgId, userId }

  const response = (await poster([URL, params])) as boolean

  return response
}

export async function updateOrganizationInfoApiCall({
  organizationId,
  name,
  avatarUrl,
  description,
}: {
  organizationId: string
  name: string
  avatarUrl?: string
  description?: string
}): Promise<boolean> {
  const URL = endpoints.organization.update_org_info
  const params = { organizationId, name, avatarUrl, description }

  const response = (await poster([URL, params])) as boolean

  return response
}

export async function updateOrganizationIdleTimerConfigApiCall({
  timeoutMinutes,
}: {
  timeoutMinutes: number
}): Promise<boolean> {
  const currentOrgId = getCurrentOrgId() as string
  const URL = endpoints.organization.update_idle_timer_config
  const params = { organizationId: currentOrgId, timeoutMinutes }

  const response = (await poster([URL, params])) as boolean

  return response
}

export async function updateOrganizationSSOSettingsApiCall({
  ssoSettings,
}: {
  ssoSettings: boolean
}): Promise<boolean> {
  const currentOrgId = getCurrentOrgId() as string
  const URL = endpoints.organization.update_sso_settings
  const params = { organizationId: currentOrgId, ssoSettings }

  const response = (await poster([URL, params])) as boolean

  return response
}

export async function updateOrganizationAiAccessApiCall({ aiAccess }: { aiAccess: boolean }): Promise<boolean> {
  const currentOrgId = getCurrentOrgId() as string
  const URL = endpoints.organization.update_ai_access
  const params = { organizationId: currentOrgId, aiAccess }

  const response = (await poster([URL, params])) as boolean

  return response
}
