import { forwardRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { Badge, Avatar, Tooltip } from '@mui/material'
//
import { CustomAvatarProps } from './types'

// ----------------------------------------------------------------------

const getCharAtName = (name: string): string => name && name.charAt(0).toUpperCase()
const getInitials = (name: string, size: string | undefined): string => {
  if (!name) {
    return ''
  }

  const parts = name.split(' ')
  if (parts.length >= 2 && size !== 'tiny' && size !== 'small') {
    return parts[0].charAt(0).toUpperCase() + parts[1].charAt(0).toUpperCase()
  }
  return parts[0].charAt(0).toUpperCase()
}

const getColorByName = (name: string): string => {
  if (['A', 'N', 'H', 'L', 'Q'].includes(getCharAtName(name))) {
    return 'primary'
  }
  if (['F', 'G', 'T', 'I', 'J'].includes(getCharAtName(name))) {
    return 'info'
  }
  if (['K', 'D', 'Y', 'B', 'O'].includes(getCharAtName(name))) {
    return 'success'
  }
  if (['P', 'E', 'R', 'S', 'U'].includes(getCharAtName(name))) {
    return 'warning'
  }
  if (['V', 'W', 'X', 'M', 'Z'].includes(getCharAtName(name))) {
    return 'error'
  }
  return 'primary'
}

// ----------------------------------------------------------------------

const CustomSquareAvatar = forwardRef<HTMLDivElement, CustomAvatarProps>(
  ({ color, name = '', src, tooltipTitle, BadgeProps, size, children, sx }, ref) => {
    const theme = useTheme()
    let height = 32
    let width = 32
    let fontSize = 14
    switch (size) {
      case 'tiny':
        height = 18
        width = 18
        fontSize = 12
        break

      case 'small':
        height = 24
        width = 24
        fontSize = 16
        break

      case 'medium':
        // default case
        break

      case 'large':
        height = 64
        width = 64
        fontSize = 32
        break

      default:
        break
    }

    const initials = getInitials(name, size)

    const colorByName = getColorByName(name)

    const colr = color || colorByName

    const renderContent = (
      <Avatar
        ref={ref}
        src={src}
        sx={{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          color: theme.palette[colr]?.contrastText,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          backgroundColor: theme.palette[colr]?.main,
          fontWeight: theme.typography.fontWeightSemiBold,
          fontSize,
          width,
          height,
          borderRadius: 1,
          ...sx,
        }}
      >
        {children}
        {!src && name && initials}
      </Avatar>
    )

    return BadgeProps ? (
      <Tooltip title={tooltipTitle || name} placement="top-start" key={name}>
        <span>
          <Badge overlap="rectangular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} {...BadgeProps}>
            {renderContent}
          </Badge>
        </span>
      </Tooltip>
    ) : (
      <Tooltip title={tooltipTitle || name} placement="top-start" key={name}>
        <span>{renderContent}</span>
      </Tooltip>
    )
  }
)
CustomSquareAvatar.displayName = 'Custom Square Avatar'

export default CustomSquareAvatar
