import { useMemo } from 'react'
// APIs
import { useEnterpriseOrganizations } from 'src/api/organization'
import { useCurrentUser } from 'src/api/user'
import { useRouter } from 'src/routes/hooks'
// routes

import { useAuthorizationContext } from 'src/auth/hooks/use-authorization-context'
import { useGetBillingErrorForOrganization } from 'src/api/account'
import { paths } from '../../routes/paths'
// components
import SvgColor from '../../components/svg-color'

// ----------------------------------------------------------------------

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  group: icon('ic_group'),
  widget: icon('ic_widget'),
  home2: icon('ic_home2'),
  settings: icon('ic_setting'),
}

// ----------------------------------------------------------------------

export function useNavData() {
  const router = useRouter()
  const { isLoading: currentUserLoading } = useCurrentUser()
  const { isLoading: organizationsLoading } = useEnterpriseOrganizations()

  const { isBillingError, BillingErrorLoading } = useGetBillingErrorForOrganization()

  const authorizationContext = useAuthorizationContext()
  const isAuthorizedToManageOrganization = authorizationContext.isAuthorizedToManageOrganization()
  const hasAnyOrganizationRole = authorizationContext.hasAnyOrganizationRole()

  // Note: Moving subscriptionsTab inside useMemo since it's part of the computation for `data`
  const data = useMemo(() => {
    if (currentUserLoading || organizationsLoading) {
      return []
    }

    if (isAuthorizedToManageOrganization) {
      return [
        {
          subheader: 'Pages',
          items: [
            { title: 'Home', icon: ICONS.home2, path: paths.home },
            { title: 'Programs', icon: ICONS.widget, path: paths.programs.root },
            {
              title: 'Settings',
              icon: ICONS.settings,
              path: paths.settings.root,
              error: isBillingError && !BillingErrorLoading,
              onClick: () => {
                router.push(paths.settings.profile)
              },
              children: [
                { title: 'Personal', path: paths.settings.profile },
                {
                  title: 'Organization',
                  path: paths.settings.organization_root,
                  error: isBillingError && !BillingErrorLoading,
                },
              ],
            },
            { title: 'Audiences', icon: ICONS.group, path: paths.audiences.root },
          ],
        },
      ]
    }

    if (hasAnyOrganizationRole) {
      return [
        {
          subheader: 'Pages',
          items: [
            { title: 'Home', icon: ICONS.home2, path: paths.home },
            { title: 'Programs', icon: ICONS.widget, path: paths.programs.root },
            { title: 'Personal Settings', icon: ICONS.user, path: paths.settings.root },
            { title: 'Audiences', icon: ICONS.group, path: paths.audiences.root },
          ],
        },
      ]
    }

    return [
      {
        subheader: 'Pages',
        items: [
          { title: 'Home', icon: ICONS.home2, path: paths.home },
          { title: 'Programs', icon: ICONS.widget, path: paths.programs.root },
          { title: 'Personal Settings', icon: ICONS.user, path: paths.settings.root },
        ],
      },
    ]
  }, [
    BillingErrorLoading,
    currentUserLoading,
    hasAnyOrganizationRole,
    isAuthorizedToManageOrganization,
    isBillingError,
    organizationsLoading,
    router,
  ])

  return data
}
